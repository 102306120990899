<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<div class="section1">
			<div class="item" v-for="(item,index) in goodsList" :key="index">
				<div class="box1">{{item.title}}</div>
				<div class="box2">
					<div class="pic">
						<img class="icon" :src="item.img" />
					</div>
					<div class="content">
						<div class="desc">{{item.description}}</div>
						<div class="info">
							<div class="price">积分：<font>{{item.money}}</font></div>
							<router-link :to="'/integral/info?id=' + item.id" class="more">查看详情</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<wd-pagination v-model="page" :total="total"></wd-pagination>
		<div class="blank_80"></div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '积分兑换',
				limit: 10,
				page: 1,
				total: 0,
				goodsList: []
			}
		},
		watch: {
			page() {
				if(this.$route.path == '/integral/list'){
					this.getIntegralLog();
				}
			}
		},
		computed: {
			uid() {
					return this.$store.state.uid;
			}
		},
		activated() {
			this.getGoodsList();
		},
		deactivated() {
			this.page = 1;
			this.total = 0;
			this.goodsList = [];
		},
		methods: {
			// 获取兑换列表
			async getGoodsList(){
				var limit = this.limit;
				var page = this.page;
				var data = {
					limit, page
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/integral/goods', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('我的积分 兑换列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.total = res.data.count;
					var goodsList = res.data.lists;
					for(var i = 0; i < goodsList.length; i++) {
						if(goodsList[i]['img'].substring(0,4) != 'http'){
							goodsList[i]['img'] = this.$store.state.http + goodsList[i]['img']
						}
					}
					this.goodsList = goodsList;
				} else {
					this.goodsList = [];
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		margin: 0.4rem 0 1rem;
	}
	.section1 .item{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-top: 0.4rem;
	}
	.section1 .item:first-child{
		margin-top: 0;
	}
	.section1 .item .box1{
		font-size: 0.3rem;
		color: #333333;
		height: 0.72rem;
		display: flex;
		align-items: center;
		padding: 0 0.3rem;
		border-bottom: 0.02rem solid #e0e0e0;
	}
	.section1 .item .box2{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0.24rem 0.3rem;
	}
	.section1 .item .box2 .pic{
		width: 1.6rem;
		height: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #dddddd;
	}
	.section1 .item .box2 .pic .icon{
		max-height: 100%;
	}
	.section1 .item .box2 .content{
		width: 5.1rem;
	}
	.section1 .item .box2 .content .desc{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.42rem;
		height: 0.84rem;
		overflow: hidden;
	}
	.section1 .item .box2 .content .info{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.24rem;
	}
	.section1 .item .box2 .content .info .price{
		font-size: 0.26rem;
		color: #333333;
	}
	.section1 .item .box2 .content .info .price font{
		color: #ff6000;
	}
	.section1 .item .box2 .content .info .more{
		width: 1.4rem;
		height: 0.44rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #ff6000;
		border: 0.02rem solid #ff6000;
		border-radius: 0.5rem;
	}
</style>